import { query, group, style, animate } from '@angular/animations';
import { AbstractControl } from '@angular/forms';
import * as go from 'gojs';
import { filter, take } from 'rxjs/operators';
import { RICH_TEXT_EDITOR_EMPTY_VALUE } from '../../rich-text/proxy/rich-text-editor.const';
import {
  nodeNames,
  QUICK_REPLY_RESPONSE_NAME,
  TEXT_GROUP_RESPONSE_NAME,
} from '../gojs/palette.consts';
import {
  API_KEY,
  AZURE_API_KEY,
  AZURE_DEPLOYMENT_NAME,
  MODEL,
  PROVIDER,
  AZURE_RESOURCE_NAME,
} from 'src/app/settings/openai-integration-settings/openai-integration.consts';
import {
  FlowModel,
  NodeFieldModel,
  NodeModel,
  QuickReplyResponseModel,
  ResponseTextWithIdModel,
  TextGroupResponseModel,
} from '../proxy/designer.model';
import { guid, NODE_FIELD_TYPE_IDS_CONTAINING_TEXT_WITH_ID, START_NODE_TYPE_ID } from '@utils';
import { ObjectHelper } from '../../helpers/object.helper';

export const isLoopBack = (link) => {
  if (!link) return false;
  return;
};

export const dropOntoLink = (e, oldlink) => {
  if (!(oldlink instanceof go.Link)) return;
  const diagram = e.diagram;
  const newNode = diagram.selection.first();
  if (!(newNode instanceof go.Node)) return;
  // Node has a link, do not link any nodes
  if (
    newNode.findLinksConnected(oldlink.data.fromPort).count ||
    newNode.findLinksConnected(oldlink.data.toPort).count > 0
  )
    return;
  if (
    !isLoopBack(oldlink) &&
    newNode !== null &&
    newNode?.data !== undefined &&
    newNode?.data.name !== nodeNames.start &&
    newNode?.data.name !== nodeNames.end &&
    newNode?.data.name !== nodeNames.exit &&
    newNode?.data.name !== nodeNames.returnToStart &&
    newNode?.data.name !== nodeNames.condition &&
    newNode?.data.name !== nodeNames.languageCondition
  ) {
    const aboveNodeKey = oldlink?.data?.from;
    const aboveNodeOutPort = oldlink?.data?.fromPort;

    const underNodeKey = oldlink?.data?.to;
    const underNodeInPort = oldlink?.data?.toPort;

    const newNodeKey = newNode?.data?.key;
    const newNodeInPort = 'in';
    let newNodeOutPort = 'out';
    const newNodeConnectPort = 'connect';
    const newNodeConfirmedPort = 'confirmed';
    const newNodeNoResultPort = 'noresult';
    const newNodeFoundPort = 'found';

    if (newNode?.data.name === nodeNames.callTransfer) {
      newNodeOutPort = newNodeConnectPort;
    } else if (newNode?.data.name === nodeNames.confirmation) {
      newNodeOutPort = newNodeConfirmedPort;
    } else if (newNode?.data.name === nodeNames.listFilter) {
      newNodeOutPort = newNodeNoResultPort;
    } else if (newNode?.data.name === nodeNames.voiceBiometrics) {
      newNodeOutPort = newNodeConfirmedPort;
    } else if (newNode?.data.name === nodeNames.generativeQuestionAnswering) {
      newNodeOutPort = newNodeFoundPort;
    }

    diagram.model.addLinkData({
      from: aboveNodeKey,
      fromPort: aboveNodeOutPort,
      to: newNodeKey,
      toPort: newNodeInPort,
    });

    diagram.model.addLinkData({
      from: newNodeKey,
      fromPort: newNodeOutPort,
      to: underNodeKey,
      toPort: underNodeInPort,
    });

    diagram.model.removeLinkData(oldlink.data);
  }
};

export const leftSliderAnimation = [
  group([
    query(
      ':enter',
      [
        style({ transform: 'translateX(-100%)' }),
        animate('.3s ease-out', style({ transform: 'translateX(0%)' })),
      ],
      {
        optional: true,
      },
    ),
    query(
      ':leave',
      [
        style({
          transform: 'translateX(0%)',
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          opacity: 0,
        }),
        animate('.3s ease-out', style({ transform: 'translateX(50%)' })),
      ],
      {
        optional: true,
      },
    ),
  ]),
];

export const rightSliderAnimation = [
  group([
    query(
      ':enter',
      [
        style({ transform: 'translateX(50%)' }),
        animate('.3s ease-out', style({ transform: 'translateX(0%)' })),
      ],
      {
        optional: true,
      },
    ),
    query(
      ':leave',
      [
        style({
          transform: 'translateX(0%)',
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          opacity: 0,
        }),
        animate('.3s ease-out', style({ transform: 'translateX(-100%)' })),
      ],
      {
        optional: true,
      },
    ),
  ]),
];

export const setTouchedIfValueChanged = (
  source: AbstractControl,
  target: AbstractControl | (() => void),
) => {
  source.valueChanges
    .pipe(
      filter((value) => !checkIfRichTextValueIsEmpty(value)),
      take(1),
    )
    .subscribe(() => {
      if (target instanceof AbstractControl) {
        target.patchValue(true, { onlySelf: true });
      } else if (target instanceof Function) {
        target();
      }
    });
};

export const checkIfRichTextValueIsEmpty = (value) =>
  value === RICH_TEXT_EDITOR_EMPTY_VALUE ||
  value === '' ||
  value === '<br>' ||
  value === '\r\n' ||
  value === '\n';

export const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const setContextIconTooltip = (localizationService) => {
  setTimeout(() => {
    const contextIcons = document.getElementsByClassName('multiple-tag-icon');

    const onMouseEnter = (e) => {
      if (!e.target.title)
        e.target.title = localizationService.instant('::ExpressionInputContextIconTooltip');
    };

    for (let i = 0; i < contextIcons.length; i++) {
      contextIcons[i].addEventListener('mouseenter', onMouseEnter);
    }
  }, 50);
};

export const checkIfNodeInputIsDynamic = (type) => {
  return type === 'Dynamic' || type === 'ExtensibleDynamic';
};

export const fancyTimeFormat = (duration: number) => {
  const hours = ~~(duration / 3600);
  const minutes = ~~((duration % 3600) / 60);
  const seconds = ~~duration % 60;

  let time = '';

  if (hours > 0) {
    time += '' + hours + ':' + (minutes < 10 ? '0' : '');
  }

  time += '' + minutes + ':' + (seconds < 10 ? '0' : '');
  time += '' + seconds;

  return time;
};

export const getStorageKey = (configService, storageKey) => {
  const currentUserName = configService.getOne('currentUser').userName;
  const currentTenantName = configService.getOne('currentTenant').name;
  const tenantUserNameKey = currentTenantName + currentUserName;
  return storageKey + tenantUserNameKey;
};

export const areOpenAiSettingsFilled = (configService, localizationService) => {
  const selectedProvider = configService.getSetting(PROVIDER) ?? '';
  switch (selectedProvider) {
    case localizationService.instant('Administration::OpenAiSettings:Provider:AzureOpenAi'):
      const deploymentNameFilled = configService.getSetting(AZURE_DEPLOYMENT_NAME) !== '';
      const resourceNameFilled = configService.getSetting(AZURE_RESOURCE_NAME) !== '';
      return (
        configService.getSetting(AZURE_API_KEY) !== '' && deploymentNameFilled && resourceNameFilled
      );
    case localizationService.instant('Administration::OpenAiSettings:Provider:OpenAi'):
      const modelFilled = configService.getSetting(MODEL) !== '';
      return configService.getSetting(API_KEY) !== '' && modelFilled;
    default:
      return false;
  }
};

export const checkFlow = (flow: FlowModel, nodeTemplates: NodeModel[]): boolean => {
  if (flow && nodeTemplates?.length) {
    const startNodes = flow.nodeDataArray.filter((n) => n.typeId === START_NODE_TYPE_ID);

    if (startNodes.length !== 1) {
      const otherNodes = flow.nodeDataArray.filter((n) => n.typeId !== START_NODE_TYPE_ID);
      let startNodeTemplate = nodeTemplates.find((t) => t.typeId === START_NODE_TYPE_ID);
      startNodeTemplate = JSON.parse(JSON.stringify(startNodeTemplate));
      startNodeTemplate.key = Math.min(...otherNodes.map((n) => n.key)) - 1;

      const locX = flow.position ? Number(flow.position.split(',')[0]) + 77 : 0;
      const locY = flow.position ? Number(flow.position.split(',')[1]) + 25 : 0;

      startNodeTemplate.loc = locX + ' ' + locY;

      flow.linkDataArray = flow.linkDataArray.filter(
        (l) => !startNodes.some((n) => n.key === l.from),
      );

      flow.nodeDataArray = [startNodeTemplate, ...otherNodes];

      return true;
    }
  }

  return false;
};

export const copyResponse = (sourceResponse) => {
  const response = ObjectHelper.deepCopy(sourceResponse);

  if (response.name === TEXT_GROUP_RESPONSE_NAME) {
    const textGroupResponse: TextGroupResponseModel = JSON.parse(response.value);
    textGroupResponse.texts.forEach((text) => (text.id = guid()));
    response.value = JSON.stringify(textGroupResponse);
  } else if (response.name === QUICK_REPLY_RESPONSE_NAME) {
    const quickReplyResponse: QuickReplyResponseModel = JSON.parse(response.value);
    quickReplyResponse.title.id = guid();
    quickReplyResponse.replies.forEach((reply) => {
      reply.title.id = guid();
      reply.value.id = guid();
      if (reply.responseArray) reply.responseArray.id = guid();
    });
    response.value = JSON.stringify(quickReplyResponse);
  }

  return response;
};

export const copyNodeFields = (nodeFields: NodeFieldModel[]) => {
  nodeFields = ObjectHelper.deepCopy(nodeFields);

  return nodeFields.map((nodeField) => {
    if (NODE_FIELD_TYPE_IDS_CONTAINING_TEXT_WITH_ID.includes(nodeField.typeId)) {
      const responseTextWithId: ResponseTextWithIdModel = JSON.parse(nodeField.value);
      responseTextWithId.id = guid();
      nodeField.value = JSON.stringify(responseTextWithId);

      return nodeField;
    }
    return nodeField;
  });
};
