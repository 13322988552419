import { RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

const configureRoutes = (routes: RoutesService) => () => {
  routes.add([
    {
      path: 'integrations/channels',
      iconClass: 'fas fa-shapes',
      name: '::Menu:ChannelIntegrations',
      parentName: '::Menu:Integrations',
      order: 1,
      requiredPolicy: 'Designer.ChannelIntegrations',
    },
    {
      path: 'integrations/services',
      iconClass: 'fas fa-link',
      name: '::Menu:ServiceIntegrations',
      parentName: '::Menu:Integrations',
      order: 2,
      requiredPolicy: 'Designer.ServiceIntegrations',
    },
    {
      path: 'integrations/llm-configurations',
      iconClass: 'fas fa-head-side-gear',
      name: '::Menu:LlmConfigurations',
      parentName: '::Menu:Integrations',
      order: 3,
      requiredPolicy: 'Designer.LlmConfigurations',
    },
  ]);
};

export const INTEGRATIONS_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];
