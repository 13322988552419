import { LlmConfigurationProvider } from 'src/app/integrations/proxy/integration.model';

export const AGENTIC_AI_FEATURE = 'AgenticAi.EnableAgenticAiFeature';
export const CUSTOM_MODEL = 'custom model';

export const AI_AGENT_FIELD_CONSTS = {
  nameMaxLength: 200,
  descriptionMaxLength: 200,
  instructionsMaxLength: 4000,
  avatarImageMaxSizeInBytes: 1024 * 100, // 100 KB
  llmModelMaxLength: 200,
};

export const AI_AGENT_FORM_NAMES = {
  generalSettings: 'generalSettings',
  interactionSettings: 'interactionSettings',
  llmSettings: 'llmSettings',
};

export const AI_AGENT_FIELD_NAMES = {
  avatar: 'avatar',
  name: 'name',
  description: 'description',
  collaborationMode: 'collaborationMode',
  persona: 'persona',
  creativityLevel: 'creativityLevel',
  responseDetailLevel: 'responseDetailLevel',
  instructions: 'instructions',
  llmConfiguration: 'llmConfiguration',
  llmModel: 'llmModel',
  llmCustomModel: 'llmCustomModel',
};

export const AI_AGENT_FORM_DATA_KEYS = {
  avatarImage: 'avatarImage',
  name: 'name',
  description: 'description',
  collaborationMode: 'collaborationMode',
  isAvatarImageChanged: 'isAvatarImageChanged',
  persona: 'persona',
  creativityLevel: 'creativityLevel',
  responseDetailLevel: 'responseDetailLevel',
  instructions: 'instructions',
  llmConfigurationId: 'llmConfigurationId',
  isCustomLlmModelSelected: 'isCustomLlmModelSelected',
  llmModel: 'llmModel',
  projectId: 'projectId',
  projectVersionId: 'projectVersionId',
};

export const LLM_MODELS = new Map<number, string[]>([
  [
    LlmConfigurationProvider.openAi,
    ['gpt-4o', 'gpt-4o-mini', 'o1', 'o1-mini', 'o3-mini', CUSTOM_MODEL],
  ],
  [
    LlmConfigurationProvider.googleGemini,
    ['gemini-2.0-flash', 'gemini-1.5-flash', 'gemini-1.5-flash-8b', 'gemini-1.5-pro', CUSTOM_MODEL],
  ],
  [
    LlmConfigurationProvider.awsBedrock,
    [
      'anthropic.claude-3-5-sonnet-20241022-v2:0',
      'anthropic.claude-3-5-haiku-20241022-v1:0',
      'anthropic.claude-3-opus-20240229-v1:0',
      'meta.llama3-3-70b-instruct-v1:0',
      'meta.llama3-2-90b-instruct-v1:0',
      'meta.llama3-2-11b-instruct-v1:0',
      'meta.llama3-2-3b-instruct-v1:0',
      'meta.llama3-2-1b-instruct-v1:0',
      'meta.llama3-1-405b-instruct-v1:0',
      'meta.llama3-1-8b-instruct-v1:0',
      CUSTOM_MODEL,
    ],
  ],
  [LlmConfigurationProvider.azureOpenAi, []],
  [LlmConfigurationProvider.sestek, []],
]);
