import { LocalizationService } from '@abp/ng.core';
import { Component, Input } from '@angular/core';
import { ValidationErrorComponent } from '@volo/abp.ng.theme.lepton';
import {
  CONTEXT_KEY_REGEX,
  PROJECT_NAME_REGEX,
  INTENT_NAME_REGEX,
  CONTEXT_VALUE_REGEX,
  ENTITY_NAME_REGEX,
  CUSTOM_DATA_KEY_REGEX,
  LLM_CONFIGURATION_NAME_REGEX,
} from '@utils';

@Component({
  selector: 'cai-validation-error',
  template: `
    <div>
      <span class="field-validation-error text-danger">
        <span
          innerHtml="{{ checkErrorMessage(abpErrors, localizationKey) | abpLocalization }}"
        ></span>
      </span>
    </div>
  `,
  styles: [
    `
      .field-validation-error.text-danger span {
        border-width: 1px;
      }
    `,
  ],
})
export class ErrorComponent extends ValidationErrorComponent {
  constructor(private localizationService: LocalizationService) {
    super();
  }

  @Input() localizationKey;

  checkErrorMessage(abpErrors, localizationKey = null) {
    for (const error of abpErrors) {
      if (error.key === 'pattern') {
        if (error.params.requiredPattern === CONTEXT_KEY_REGEX) {
          return this.localizationService.instant('Exception::Raven:010014');
        } else if (error.params.requiredPattern === CONTEXT_VALUE_REGEX) {
          return this.localizationService.instant('Exception::Raven:010033');
        } else if (error.params.requiredPattern === ENTITY_NAME_REGEX) {
          return this.localizationService.instant('Project::EntityNameFieldPatternIsInvalid');
        } else if (error.params.requiredPattern === PROJECT_NAME_REGEX) {
          return this.localizationService.instant('Project::ProjectNameFieldPatternIsInvalid');
        } else if (error.params.requiredPattern === LLM_CONFIGURATION_NAME_REGEX) {
          return this.localizationService.instant('LlmConfiguration::NameFieldPatternIsInvalid');
        } else if (error.params.requiredPattern === INTENT_NAME_REGEX) {
          return this.localizationService.instant('Project::IntentNameFieldPatternIsInvalid');
        } else if (error.params.requiredPattern === CUSTOM_DATA_KEY_REGEX) {
          return this.localizationService.instant('::CustomDataKeyFieldPatternIsInvalid');
        } else {
          return this.localizationService.instant('::ThisFieldPatternIsInvalid');
        }
      } else if (error.key === 'required') {
        return error.message;
      } else if (error.key === 'max') {
        return this.localizationService.instant(
          { key: '::SelectedInputMaxValue', defaultValue: error.params.max },
          error.interpoliteParams[0],
          '',
        );
      } else if (error.key === 'min') {
        return this.localizationService.instant(
          { key: '::SelectedInputMinValue', defaultValue: error.params.min },
          error.interpoliteParams[0],
          '',
        );
      } else if (error.key === 'minlength') {
        return this.localizationService.instant(
          { key: '::SelectedInputMinLengthValue', defaultValue: error.params.requiredLength },
          error.interpoliteParams[0],
          '',
        );
      } else if (error.key === 'maxlength') {
        return this.localizationService.instant(
          { key: '::SelectedInputMaxLengthValue', defaultValue: error.params.requiredLength },
          error.interpoliteParams[0],
          '',
        );
      } else if (error.key === 'urlShouldStartWithHttpOrHttps') {
        return this.localizationService.instant(
          'Project::GenerativeQnA:Url:WebSiteUrlShouldStartWithHttpOrHttps',
        );
      } else if (error.key === 'excludeUrlProtocolShouldBeSameAsUrl') {
        return this.localizationService.instant(
          'Project::GenerativeQnA:Url:ExcludeUrlsProtocolShouldBeSameAsUrl',
        );
      } else if (error.key === 'searchKeywordShouldBeTwoOrMoreCharacters') {
        return this.localizationService.instant(
          'ResponseManagement::SearchKeywordShouldBeTwoOrMoreCharacters',
        );
      } else if (error.key === 'maxLinesOfExcludeUrlsExceeded') {
        return this.localizationService.instant(
          'Project::GenerativeQnA:Url:MaxLinesOfExcludeUrlsExceeded',
        );
      } else if (error.key === 'splitChunkOverlapShouldNotExceedSplitChunkSize') {
        return this.localizationService.instant(
          'Administration::OpenAiSettings:Advanced:SplitChunkOverlap:MaxValueError',
        );
      } else {
        return error.message;
      }
    }
    if (localizationKey != null) {
      return this.localizationService.instant(localizationKey);
    }
  }
}
