import { FullAuditedEntityDto, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { ProjectDto } from 'src/app/projects/proxy/project.models';

export interface IntegrationDto extends FullAuditedEntityDto<string> {
  platform: string;
  provider: string;
  fields: string;
  name: string;
  uniqueId: string;
}

export interface ChannelIntegrationDto extends IntegrationDto {
  project: string;
}

export interface ServiceIntegrationDto extends IntegrationDto {
  languageId?: string;
  projects: ProjectDto[];
  isReadonly: boolean;
}

export interface GetIntegrationsInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  name?: string;
  type: number;
}

export interface IntegrationCreateUpdateDtoBase {
  id?: string;
  name: string;
  platform: string;
  provider: string;
  fields: string;
  uniqueId: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface ChannelIntegrationCreateUpdateDto extends IntegrationCreateUpdateDtoBase {}

export interface ServiceIntegrationCreateUpdateDto extends IntegrationCreateUpdateDtoBase {
  languageId?: string;
}

export interface MultipleInputGroup {
  maxRowCount: number;
  inputs: IntegrationInput[];
}

export interface IntegrationInput {
  name: string;
  isMasked: boolean;
  isMandatory: boolean;
  isEncrypted: boolean;
  isAppUniqueId: boolean;
  description: string;
  defaultValue: string;
  type: IntegrationInputType;
  range: number[];
  stringLength: number[];
  tooltip: string;
  placeholder: string;
  valueTemplate: string;
  validationPattern: string;
  tabGroup: string;
  cellGroup: number;
  selectOptions: any;
  isReadOnly: boolean;
  multipleInputGroup: MultipleInputGroup;
}

export interface RavenIntegrationDto {
  name: string;
  provider: string;
  platform: string;
  channelId: string;
  inputs: IntegrationInput[];
}

export interface RavenMetaDataListDto {
  version: string;
  integrations: RavenIntegrationDto[];
  platforms: string[];
}

export interface IntegrationAvailabilityDto {
  platform: string;
  provider: string;
  isAvailable: boolean;
}

export interface GetLlmConfigurationsInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
}

export class LlmConfigurationSummaryDto extends FullAuditedEntityDto<string> {
  name: string;
  provider: string;
}

export class LlmConfigurationDto extends FullAuditedEntityDto<string> {
  name: string;
  provider: LlmConfigurationProvider;
  openAiApiKey?: string;
  azureOpenAiApiKey?: string;
  azureOpenAiResourceName?: string;
  azureOpenAiDeploymentName?: string;
  azureOpenAiApiVersion?: string;
  awsBedrockRegion?: string;
  awsBedrockAccessKeyId?: string;
  awsBedrockSecretAccessKey?: string;
  googleGeminiApiKey?: string;
}

export enum LlmConfigurationProvider {
  openAi = 0,
  azureOpenAi = 1,
  awsBedrock = 2,
  googleGemini = 3,
  sestek = 4,
}

export enum IntegrationType {
  channel = 'channel',
  service = 'service',
}

export enum ServiceIntegrationPlatformType {
  SpeechRecognition = 'Speech Recognition',
  TextToSpeech = 'Text-to-Speech',
  Sentiment = 'Sentiment',
  VoiceBiometrics = 'Voice Biometrics',
  TextLanguageDetection = 'Text-Language Detection',
  LiveChat = 'Live Chat',
  Translation = 'Translation',
}

export enum ServiceIntegrationProviderType {
  Sestek = 'Sestek',
  Generic = 'Generic',
  Alotech = 'Alotech',
  Aacc = 'Aacc',
  Genesys = 'Genesys',
  Tegsoft = 'Tegsoft',
  Twilio = 'Twilio',
}

export enum ChannelIntegrationPlatformType {
  WebChat = 'WebChat',
}

export enum ChannelIntegrationProviderType {
  Sestek = 'Sestek',
}

export enum IntegrationInputType {
  text = 'text',
  number = 'number',
  bool = 'bool',
  select = 'select',
  color = 'color',
  dynamic = 'dynamic',
  multipleInputGroup = 'multipleInputGroup',
  chips = 'chips',
}
